import { AuthConfig } from "./AuthConfig";

export function validateToken(
  token: string,
  user_key: string | null
): Promise<object | boolean> {
  const authConfig = AuthConfig.getInstance();
  const params: { token: string | undefined; user_key: string | undefined } = {
    token: undefined,
    user_key: undefined
  };
  if (token) {
    params.token = token;
  }
  if (user_key) {
    localStorage.setItem("user_key", user_key);
    params.user_key = user_key;
  }
  return authConfig.sbxCoreService
    .run(authConfig.validateCS, params, authConfig.testMode)
    .then((res: any) => {
      if (res.success) {
        const data = res.response;
        authConfig.sbxSessionService.updateUser({
          token: data.token,
          user: data.user
        });
        return data;
      }
      authConfig.sbxSessionService.logout();
      return false;
    })
    .catch(() => {
      authConfig.sbxSessionService.logout();
      return false;
    });
}
